<template>
  <div class="architecture box" :style="val.backgroundImg">
    <p>{{val.name}}</p>
    <v-img
        class="img"
        :attachment-id="val.img"
        :default-img="require('@/assets/img/kctp.png')"
    />
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  name: "things_arc",
  components:{
    VImg,
  },
  props:['val']
}
</script>

<style scoped lang="scss">
.box{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  >p{
    margin-top: 50px;
    color: #333333 ;
    font-size: 30px;
  }
}
.architecture{
  height: 900px;
  background-repeat: no-repeat;
  background-size: cover ;
  background: #FFFFFF;
  >.img{
    width: 1280px;
    height: 710px;
    display: block;
    margin-top: 50px;
  }
}
</style>
