<template>
  <div class="introduction box" :style="val.backgroundImg">
    <p>{{val.name}}</p>
    <div class="backgroundInt-con">
<!--      <img :src="val.img" alt="图片">-->
      <v-img
          class="img"
          :attachment-id="val.img"
          :default-img="require('@/assets/img/kctp.png')"
      />
      <p>{{val.text}}</p>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  name: "things_int",
  components:{
    VImg,
  },
  props:['val']
}
</script>

<style scoped lang="scss">
.box{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  >p{
    margin-top: 50px;
    color: #333333 ;
    font-size: 30px;
  }
}
.introduction{
  height: 590px;
  background-repeat: no-repeat;
  background-size: cover ;
  position: relative;
  >.backgroundInt-con{
    width: 1280px;
    height: 350px;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
    >.img{
      display: block;
      margin: 40px;
      width: 470px;
      height: 270px;
    }
    >p{
      width: 600px;
     // margin-top: 71px;
      font-size: 16px;
      color: #333333;
    }
  }
}
</style>
